import { Box } from "@material-ui/core";
import React from "react";
import cx from "classnames";
import { useOptionStyles } from "../../../../utils/loan-application.styles";

const OptionSwitch = ({ options, selectedOption, handleSelect }) => {
  const optionClasses = useOptionStyles();

  const generateClasses = (value = "") => {
    return cx(optionClasses.option, {
      [optionClasses.selected]: selectedOption === value,
    });
  };

  return (
    <Box display="flex" width="fit-content" height="fit-content">
      {options.map((option) => (
        <Box
          key={option.value}
          classes={{ root: generateClasses(option.value) }}
          onClick={() => handleSelect(option.value)}
        >
          {option.label}
        </Box>
      ))}
    </Box>
  );
};

export default OptionSwitch;
